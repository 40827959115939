import en from './en.json';
import nl from './nl.json';
import importer from './importer';

const translations = {
  en: { base: en, screens: importer('en') },
  nl: { base: nl, screens: importer('nl') },
};

export default translations;
