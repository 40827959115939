import { useNavigate } from 'react-router-dom';
import Button from '../components/Button';

export default function PageNotFoundScreen() {
  const navigate = useNavigate();

  return (
    <div className="min-h-screen pt-8 p-6 bg-blue">
      <h1 className="text-center text-3xl font-bold text-white mb-6 after:border-b after:block after:w-10 after:mx-auto after:pt-8">
        Page not found
      </h1>
      <div className="text-center">
        <Button label="Ga naar start scherm" variant="quaternary" onClick={() => navigate('/', { replace: true }) } />
      </div>
    </div>
  );
};
