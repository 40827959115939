import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Button from '../../components/Button';
import Markdown from '../../components/Markdown';

export default function WelcomeScreen() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  async function handleStartSession() {
    sessionStorage.setItem('autoPlay', 'true');
    navigate('/session/100', { replace: true });
  }

  return (
    <div className="min-h-screen pt-8 p-6 bg-blue">
      <h1 className="text-center text-3xl font-bold text-white mb-6 after:border-b after:block after:w-10 after:mx-auto after:pt-8">
        {t('screens:welcome.title')}
      </h1>
      <div className="p-6 bg-white shadow-xl min-h-screen-card flex flex-col justify-between">
        <div className="flex-grow">
          <Markdown value={t('screens:welcome.text')} />
        </div>
        <div className="text-start mt-3">
          <span>{t('screens:welcome.user_rights_agreement_text')} <a href='user_rights_agreement' style={{ color: "#75C6CB" }}><b><u>{t("user_rights_agreement")}</u></b></a></span>
        </div>
        <div className="flex-none mt-3 flex gap-2 justify-end">
          <Button label={t('continue')} variant="primary" onClick={handleStartSession} />
        </div>
      </div>
    </div>
  );
};
